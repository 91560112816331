import { ImagePayload } from "components/NewEditor/nodes/SlidImageNode/ImageNode";
import { NodeKey } from "lexical";
import { create } from "zustand";

export const useImageNodeStore = create<{
  showImageMarkupPopup: boolean;
  setShowImageMarkupPopup: (showImageMarkupPopup: boolean) => void;
  selectedImageData: ImagePayload | null;
  setSelectedImageData: (selectedImageData: ImagePayload) => void;
  selectedImageNodeKey: NodeKey | null;
  setSelectedImageNodeKey: (selectedImageNodeKey: NodeKey) => void;
  showImageCroppingModal: boolean;
  setShowImageCroppingModal: (showImageCroppingModal: boolean) => void;
}>((set) => ({
  showImageMarkupPopup: false,
  setShowImageMarkupPopup: (showImageMarkupPopup) => set({ showImageMarkupPopup }),
  selectedImageData: null,
  setSelectedImageData: (selectedImageData) => set({ selectedImageData }),
  selectedImageNodeKey: null,
  setSelectedImageNodeKey: (selectedImageNodeKey) => set({ selectedImageNodeKey }),
  showImageCroppingModal: false,
  setShowImageCroppingModal: (showImageCroppingModal) => set({ showImageCroppingModal }),
}));
